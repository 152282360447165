import React from 'react'
import Layout from '../components/layout'
import Hero from '../components/hero'
import { StaticQuery, Link } from 'gatsby'
import Img from 'gatsby-image'

import videoSrc from '../assets/home-trim.mp4' // Static video file for this page
class IndexPage extends React.Component {
    
    render() {  
        return (
            
            <Layout containerClass="index">
                <Hero  video={videoSrc} mobile={this.props.data.header.childImageSharp.fluid}/>
                <div className="main" id="main">
                    <section className="intro">
                        <div className="uk-flex uk-flex-left uk-flex-middle uk-flex-wrap">
                                <div className="uk-width-1-1 uk-width-1-2@l text">
                                    <h2 className="h1">Allcor Fabrication and Construction</h2>
                                    <p>Since launching in 2015, Allcor Fabrication has undergone strong growth in the region reflecting not just the suite of services on offer but also the company’s reputation for completing projects on time and to a high standard, while sticking to the available budget.</p>
                                </div>
                                {  }
                                <div className="uk-width-1-1 uk-width-1-2@l uk-cover-container about">
                                    <Img fluid={this.props.data.about.childImageSharp.fluid} className="uk-height-viewport"/>
                                    <div className="uk-overlay uk-position-center"> 
                                        <Link className="big-link" to='about'>
                                            <p className="h2">About</p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                    </section>
                    <section className="grey call-out">
                        <div className="uk-container uk-container-center">
                            <div className="uk-flex uk-flex-center uk-flex-middle">
                                <div className="uk-width-4-5@l box">
                                    <h2 className="h1">Services</h2>
                                    <p>The services we provide complement each other where we can add value to a project, with in-house fabrication, 
                                        construction, design and drafting services. We work for our customers by exceeding their expectations.</p>
                                    <p>From day one all the way through to the ribbon cutting, we put your interests ahead of ours. </p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="services">
                        <div className="uk-flex uk-flex-wrap">
                            <div className="uk-width-1-3@l uk-cover-container">
                                <Img fluid={this.props.data.fab.childImageSharp.fluid} className="uk-cover" />
                                <div className="uk-overlay uk-position-center"> 
                                    <Link className="big-link" to='fabrication'>
                                        <p className="h2">Fabrication</p>
                                    </Link>
                                </div>
                            </div>
                            <div className="uk-width-1-3@l uk-cover-container">
                                <Img fluid={this.props.data.con.childImageSharp.fluid}  className="uk-cover" />
                                <div className="uk-overlay uk-position-center"> 
                                        <Link className="big-link" to='construction'>
                                            <p className="h2">Construction</p>
                                        </Link>
                                </div>
                            </div>
                            <div className="uk-width-1-3@l uk-cover-container">
                                <Img fluid={this.props.data.draft.childImageSharp.fluid} className="uk-cover" />
                                <div className="uk-overlay uk-position-center">       
                                    <Link className="big-link" to='design'>
                                        <p className="h2">Design / Drafting</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                
            </Layout>
        )
    }
}

export default IndexPage


export const query = graphql`
  query indexQuery {
    header:file(relativePath: { eq: "new/PH01.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 1200) {
                src
                srcSet
                aspectRatio
                sizes
                base64
            }
          }
    }
    fab:file(relativePath: { eq: "home/fab.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 600) {
                src
                srcSet
                aspectRatio
                sizes
                base64
            }
          }
    }
    con:file(relativePath: { eq: "home/conRotate.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 600) {
                src
                srcSet
                aspectRatio
                sizes
                base64
            }
        }
    }
    about:file(relativePath: { eq: "new/20190806_094514.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 1600) {
                src
                srcSet
                aspectRatio
                sizes
                base64
            }
          }
    }
    draft:file(relativePath: { eq: "new/PH02.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
                src
                srcSet
                aspectRatio
                sizes
                base64
            }
          }
    }
  }
`;